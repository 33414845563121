(() => {
    const $siteMain = document.querySelector('.site-main');

    const $blockAnchorLinks = document.querySelectorAll('.block-container a[href^="#"]');

    const initBlockAnchorLinks = () => {
        if (!$blockAnchorLinks) return;

        $blockAnchorLinks.forEach($blockAnchorLink => {
            $blockAnchorLink.addEventListener('click', e => {
                const url = new URL($blockAnchorLink.href);

                const $targetEl = document.querySelector(url.hash);

                if (!url.hash || !$targetEl) return;

                e.preventDefault();

                window.scrollTo({
                    top: $targetEl.offsetTop - $siteMain.offsetTop,
                    behavior: 'smooth',
                });

                history.pushState({}, '', url.href);
            });
        });
    };

    initBlockAnchorLinks();
})();
