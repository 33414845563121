let $onScreen = document.querySelectorAll('.on-screen');

const setTransitionDelays = () => {
    $onScreen.forEach($el => {
        const $onScreenTransitionEls = $el.querySelectorAll('[data-on-screen-transition-delay]');

        $onScreenTransitionEls.forEach($onScreenTransitionEl => {
            const onScreenTransitionDelay = $onScreenTransitionEl?.dataset?.onScreenTransitionDelay ? 
                JSON.parse($onScreenTransitionEl.dataset.onScreenTransitionDelay) : null;

            if (!onScreenTransitionDelay) return;

            if (typeof onScreenTransitionDelay === 'number') {
                $onScreenTransitionEl.style.transitionDelay = `${onScreenTransitionDelay}s`;
            }

            if (typeof onScreenTransitionDelay === 'object') {
                for (const key in onScreenTransitionDelay) {
                    if (window.innerWidth < parseInt(key)) continue;

                    $onScreenTransitionEl.style.transitionDelay = `${onScreenTransitionDelay[key]}s`;
                }
            }
        });
    });
}
    
export const setOnScreenEls = () => {
    $onScreen = document.querySelectorAll('.on-screen');
}

export const checkOnScreenEls = () => {
    $onScreen.forEach($el => {
        const $onScreenTransitionEls = $el.querySelectorAll('[data-on-screen-transition-delay]');

        if ($el.getBoundingClientRect().top <= (window.innerHeight * 0.8) || 
            (window.scrollY + window.innerHeight) >= document.body.scrollHeight) {

            $el.classList.add('is-on-screen');
        } else {
            $onScreenTransitionEls.forEach($onScreenTransitionEl => {
                $onScreenTransitionEl.style.transitionDelay = '';
            });

            $el.classList.remove('is-on-screen');
        }
    });
}

(() => {
    if (!$onScreen.length) return;

    window.addEventListener('scroll', () => {
        checkOnScreenEls();
    });

    window.addEventListener('load', () => {
        checkOnScreenEls();
    });

    window.addEventListener('resize', () => {
        setTransitionDelays();
    });
    
    setTransitionDelays();
    checkOnScreenEls();
})();
